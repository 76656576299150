.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 573px;
  height: calc(90vh - 56px);

  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(3.5px);
  }
}

#root .login .card {
  height: 400px;
  width: 600px;
  max-width: 600px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--bg-white);

  .mt-3 {
    font-size: 28px;
  }
}

.login .row.okta {
  margin-bottom: 32px;
}

.login button.icon-btn {
  color: white !important;
  background-color: #007cc0 !important;
  height: 65px;
  padding-left: 8px;
  padding-right: 32px;
  margin-top: 20px;
}
