.container.amway-products-catalog {
  padding: 0;
}

.container.amway-products-catalog .card {
  margin: 32px 24px;
  padding: 20px;
}

.container.amway-products-catalog .card-body {
  margin: 0;
  padding: 0 !important;
}

.amway-products-catalog {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .file-upload-container {
      svg {
        margin-right: 10px;
      }

      .import-btn {
        background-color: #007bff;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }

      .import-btn:disabled {
        background-color: #c0c0c0;
        cursor: not-allowed;
      }

      .import-btn:hover:not(:disabled) {
        background-color: #0056b3;
      }

      .progress-bar-container {
        position: relative;
        width: 100%;
        max-width: 400px;
        height: 20px;
        background-color: #f3f3f3;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      .progress-bar {
        height: 100%;
        background-color: #4caf50;
        transition: width 0.3s ease;
      }

      .progress-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        pointer-events: none;
      }
    }
  }
}

.products-filter-container {
  display: flex;
  gap: 20px; /* Space between each filter */
  align-items: center; /* Aligns items in a row */
}

.multi-select-dropdown {
  width: 180px;
}

.item-name-input {
  width: 250px;
}

.search-btn {
  margin-left: auto;
  margin-top: 22px;
  display: flex;
  align-items: center;
}

.loading-div {
  text-align: center;
}

.tooltip {
  --bs-tooltip-max-width: 400px;

  .tooltip-inner {
    text-align: justify;
  }
}

.category-input {
  .form-label {
    margin-left: 12px;
    font-size: 12px;
    margin-bottom: 0 !important;
    margin-top: 14px;
  }
}
