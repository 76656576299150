.single-select-dropdown {
  &.primary {
    .dropdown > .dropdown-toggle.btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white !important;
      border-color: #c2cace !important;
      color: #c2cace !important;

      &:disabled {
        background-color: #e9ecef !important;
      }
    }
  }

  &.secondary {
    .form-label {
      margin-left: 12px;
      font-size: 12px;
      margin-bottom: 0 !important;
    }

    .dropdown > .dropdown-toggle {
      &:focus {
        box-shadow: none !important;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: var(--bg-white) !important;
      border: none !important;
      border: 1px solid #c2cace !important;
    }
  }
}

.single-select-dropdown .dropdown.selected > .dropdown-toggle.btn {
  color: black !important;
}

.single-select-dropdown .dropdown-toggle {
  min-height: 38px;
}

.single-select-dropdown .dropdown-toggle,
.single-select-dropdown .dropdown-menu {
  width: 100%;
}

.single-select-dropdown .dropdown.disabled {
  cursor: not-allowed;
}

.single-select-dropdown .dropdown-menu.show {
  overflow-y: scroll;
  max-height: 175px;
}

.single-select-dropdown .required.form-label:after {
  content: '*';
  color: red;
}

.single-select-dropdown .dropdown-item.active {
  background-color: #715fcc;
}
