.container.semantic-cache-edit {
  padding: 0;
}

.container.semantic-cache-edit .card {
  margin: 32px 24px;
  padding: 20px;
}

.container.semantic-cache-edit .card-body {
  margin: 0;
  padding: 0 !important;
}

.cache-filter-container {
  display: flex;
  gap: 20px; /* Space between each filter */
  align-items: center; /* Aligns items in a row */
}

.language-selector,
.market-selector {
  width: 180px;
}

.food-name-input {
  width: 250px;
}

.date-picker {
  width: 150px;
}

.search-btn {
  margin-left: auto;
  margin-top: 22px;
  display: flex;
  align-items: center;
}

.loading-div {
  text-align: center;
}

.tooltip {
  --bs-tooltip-max-width: 400px;

  .tooltip-inner {
    text-align: justify;
  }
}
