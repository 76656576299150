.container.coming-soon {
  padding: 0;
}

.container.coming-soon .card {
  margin: 32px 24px;
  height: calc(100vh - 120px);
}

.container.coming-soon .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.container.coming-soon img {
  margin-bottom: 22px;
}

.container.coming-soon h1 {
  font-weight: bold;
  font-size: 48px;
  color: #715fcc;
  margin-bottom: 15px;
}

.container.coming-soon h4 {
  font-size: 20px;
  color: #222222;
}
