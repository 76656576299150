:root {
  --primary: #2b6747;
  --secondary: #c9dfd3;
  --tertiary: #f0f8f4;

  --text-black: #424242;
  --text-gray: #858e93;

  --detail-gray: #a1acb2;
  --detail-border: #cdcdcd;

  --warning-error: #ff4949;
  --warning-success: #4aa04a;
  --warning-orange: #fa9f47;
  --warning-blue: #4994ee;
  --warning-disable: #dddddd;

  --bg-white: #ffffff;
  --bg-gray: #f5f5f5;
  --bg-yellow: #fff3d6;
  --bg-red: #fff0f0;
  --bg-green: #cdfcbd;
  --bg-blue: #e4effd;

  --box-shadow-small: 0px 2px 4px #b3b3b3;
}

body {
  margin: 0;
  background-color: #f6f5fb;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea:focus,
input:focus,
button:focus {
  border-color: rgba(113, 95, 204, 0.8) !important;
  box-shadow: 0 0 0 0.25rem rgba(113, 95, 204, 0.25) !important;
}

a:hover {
  color: #715fcc;
}

.container {
  background-color: transparent;
}

.bg-primary {
  background-color: #715fcc !important;
}

.form-control::placeholder {
  color: #c2cace !important;
}

.badge.bg-primary,
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.dropdown-item:active {
  background-color: #715fcc !important;
}

.badge {
  border-radius: 72px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: rgba(113, 95, 204, 0.25);
}

.btn-primary {
  border-color: #715fcc;
}

.btn-primary:disabled {
  pointer-events: unset;
  cursor: not-allowed;
}

.btn-primary:active,
.btn-primary:focus {
  border-color: rgba(113, 95, 204, 0.9);
  box-shadow: 0 0 0 0.25rem rgba(113, 95, 204, 0.25);
}

.btn-primary:hover {
  background-color: rgba(113, 95, 204, 0.87);
  border-color: rgba(113, 95, 204, 0.85);
}

.form-label {
  color: #808a8f;
}
