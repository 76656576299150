.container.dashboard {
  padding: 0;

  .card {
    margin: 32px 24px;
  }

  .card-body {
    margin-top: 28px;
    margin-left: 32px;
    margin-right: 38px;
    margin-bottom: 45px;
    padding: 0 !important;
  }

  .row {
    margin-bottom: 24px;
  }
}
